<!--
 * @Date: 2021-11-05 14:40:17
 * @LastEditors: wxb
 * @LastEditTime: 2021-11-11 12:11:17
 * @Description:
-->
<template>
  <div class="containner">
    <headerNav :activeIdx='8'></headerNav>
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>项目业绩</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="search-info">
        <div class="project-search">
          <div class="project-search-input">
            <el-input class="project-search-field"
                      v-model="searchForm.keyword"
                      placeholder="输入关键词搜索..."
                      clearable
                      @keyup.enter.native="searchNotice"></el-input>
            <button type="button"
                    class="project-search-btn"
                    @click="searchNotice">搜索</button>
          </div>
        </div>
        <div class="search-senior">
          <button @click="showSenior=!showSenior">高级搜索 <i class="el-icon-arrow-up"
               v-show="showSenior"></i>
            <i class="el-icon-arrow-down"
               v-show="!showSenior"></i>
          </button>
        </div>
      </div>
      <div class="project-search-senior"
           v-show="showSenior">
        <div class="project-search-senior-item">
          <div class="project-search-senior-item-title">中标金额：</div>
          <div class="project-search-senior-item-content">
            <div class="price-range">
              <div class="price-range-item">
                <el-input v-model="searchForm.minWinBidAmount"
                          size="small"
                          placeholder="请输入中标金额（万元）"></el-input>
              </div>
              <div class="price-range-item-delive">-</div>
              <div class="price-range-item">
                <el-input v-model="searchForm.maxWinBidAmount"
                          size="small"
                          placeholder="请输入中标金额（万元）"></el-input>
              </div>
              <div class="price-range-item-btn">
                <el-button size="small"
                           type="primary"
                           @click="searchNotice">确定</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="project-search-senior-item">
          <div class="project-search-senior-item-title">中标时间：</div>
          <div class="project-search-senior-item-content">
            <div class="notice-type-content">
              <div class="notice-type-content-item"
                   :class="index===activePublishDate ? 'active' : ''"
                   v-for="(item,index) in publishTypeList"
                   :key="index"
                   @click="handleClick4PublishType(index)">{{item}}</div>
              <div class="publish-time">
                <el-date-picker v-model="publishDate"
                                type="daterange"
                                unlink-panels
                                size="small"
                                value-format="yyyy/MM/dd"
                                range-separator="-"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                @change="handlePublishChange">
                </el-date-picker>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="result-count"
           v-show="canSee">
        为您找到{{total}}个符合条件的项目业绩
      </div>
      <div class="result-list"
           v-show="canSee">
        <el-table :data="tableData"
                  style="width: 100%">
          <template slot="empty">
            <noData tips="未找到相关项目业绩信息"
                    v-show="noData"></noData>
          </template>
          <el-table-column prop="projectName"
                           label="项目名称"
                           align="center"></el-table-column>
          <el-table-column prop="orgName"
                           label="中标机构"
                           align="center">
            <template slot-scope="scope">
              <el-button v-if="scope.row.orgName"
                         type="text"
                         size="small"
                         @click="handleClickItem(scope.row)"
                         :title="scope.row.orgName">{{scope.row.orgName}}</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="winBidAmount"
                           label="中标金额（万元）"
                           align="center"
                           width="150">
            <template slot-scope="scope">{{scope.row.winBidAmount | formatMoney}}</template>
          </el-table-column>
          <el-table-column prop="winBidDate"
                           label="中标日期"
                           align="center"
                           width="150">
            <template slot-scope="scope">{{scope.row.winBidDate | formatterDate}}</template>
          </el-table-column>
        </el-table>
      </div>
      <div class="project-page"
           v-show="!noData && canSee">
        <el-pagination background
                       layout="prev, slot, next"
                       :total="total"
                       @current-change="handleCurrentChange"
                       :current-page.sync="currentPage">
          <span class="current-page">{{currentPage}}</span>
        </el-pagination>
      </div>
    </div>
    <viporlogin bgStr="5"
                :type="viporlogin"
                v-show="!canSee"></viporlogin>
    <footerNav></footerNav>
  </div>
</template>

<script>
import headerNav from '@/components/headerNavBlue'
import footerNav from '@/components/footerNav'
import noData from '@/components/noData'
import viporlogin from '@/components/viporlogin'
import { dateFormat } from '@/utils/common'
import { authenticate } from '@/utils/authenticate'
import { getToken } from '@/utils/auth'
import { mapGetters } from 'vuex'
import { getPerformanceList } from '@/api/achievement'
export default {
  name: 'achievement',
  components: {
    noData,
    viporlogin,
    headerNav,
    footerNav
  },
  computed: {
    ...mapGetters({
      memberType: 'memberType'
    })
  },
  data() {
    return {
      noData: false,
      canSee: true,
      viporlogin: 'login',
      searchForm: {
        start: 0,
        limit: 10
      },
      showSenior: true,
      activePublishDate: 0,
      publishTypeList: ['全部', '近1年', '近2年', '近3年'],
      publishDate: '',
      tableData: [],
      currentPage: 1,
      total: 100
    }
  },
  mounted() {
    if (getToken()) {
      if (this.memberType === 'tourist') {
        this.$store.dispatch('getMemberInfo').then(res => {
          this.authCheck()
        })
      } else {
        this.authCheck()
      }
    } else {
      this.authCheck()
    }
  },
  methods: {
    // 授权检查
    authCheck() {
      const authObj = authenticate('achievement', this.memberType)
      if (authObj.flag) {
        // 调用查询列表接口
        this.searchNotice()
        this.canSee = true
      } else {
        // 根据msg值不同展示不同的提示信息
        this.canSee = false
        this.viporlogin = authObj.msg
      }
    },
    searchNotice() {
      this.currentPage = 1
      this.getList()
    },
    getList() {
      // 执行搜索
      getPerformanceList(this.searchForm).then(data => {
        if (data.ok) {
          this.total = data.cnt
          if (data.cnt > 0) {
            this.noData = false
          } else {
            this.noData = true
          }
          this.tableData = data.lst
        } else {
          this.$notify.error({
            title: '错误',
            message: data.msg
          })
        }
      }).catch(error => {
        this.$notify.error({
          title: '错误',
          message: error
        })
      })
    },
    // 时间变化
    handlePublishChange(publishDate) {
      if (!this.canSee) {
        return
      }
      if (publishDate) {
        this.activePublishDate = ''
        this.searchForm.minWinBidDate = publishDate[0]
        this.searchForm.maxWinBidDate = publishDate[1]
      } else {
        this.activePublishDate = 0
        this.searchForm.minWinBidDate = ''
        this.searchForm.maxWinBidDate = ''
      }
      this.searchNotice()
    },
    // 发布日期选择
    handleClick4PublishType(index) {
      if (index === 0) {
        this.searchForm.minWinBidDate = ''
        this.searchForm.maxWinBidDate = ''
      } else if (index === 1) {
        // 近一年
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 365 * 24 * 60 * 60 * 1000)
        this.searchForm.minWinBidDate = dateFormat('YYYY/mm/dd', start)
        this.searchForm.maxWinBidDate = dateFormat('YYYY/mm/dd', end)
      } else if (index === 2) {
        // 近两年
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 2 * 365 * 24 * 60 * 60 * 1000)
        this.searchForm.minWinBidDate = dateFormat('YYYY/mm/dd', start)
        this.searchForm.maxWinBidDate = dateFormat('YYYY/mm/dd', end)
      } else if (index === 3) {
        // 近三年
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3 * 365 * 24 * 60 * 60 * 1000)
        this.searchForm.minWinBidDate = dateFormat('YYYY/mm/dd', start)
        this.searchForm.maxWinBidDate = dateFormat('YYYY/mm/dd', end)
      }
      this.activePublishDate = index
      this.publishDate = ''
      this.searchNotice()
    },
    handleClickItem(row) {
      const id = row.id
      const routeData = this.$router.resolve({
        name: 'orgDetail',
        params: {
          id
        }
      })
      window.open(routeData.href, '_blank')
    },
    // 处理分页
    handleCurrentChange(val) {
      this.searchForm.start = (val - 1) * this.searchForm.limit
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 144rem;
  margin: 0 auto;
}

.el-breadcrumb {
  height: 5.4rem;
  line-height: 5.4rem;
}

// 搜索
.search-info {
  margin: 0 auto;
  width: 700px;
  display: flex;

  .search-senior {
    flex: 1;
    margin-left: 20px;
    button {
      width: 100px;
      height: 40px;
      background-color: #398ce2;
      border: 1px solid #398ce2;
      font-size: 14px;
      letter-spacing: 0px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
// 搜索框
.project {
  &-search {
    background: #ffffff;
    &-input {
      width: 660px;
      height: 40px;
      display: flex;
      margin: 0 auto;
    }

    &-field {
      flex: 1;
      border: solid 2px #398ce2;
      background-color: #ffffff;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0px;
      color: #898a8c;
      /deep/ .el-input__inner {
        border-radius: 0;
        height: 36px;
        line-height: 36px;
      }
    }

    &-btn {
      right: 0;
      width: 56px;
      height: 40px;
      background-color: #398ce2;
      border: 1px solid #398ce2;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}

// 筛选信息
.project {
  &-search-senior {
    background: #ffffff;
    margin: 10px auto;
    padding: 5px 10px;
    &-item {
      display: flex;
      font-size: 14px;
      padding: 18px 0px 10px;
      &:not(:last-child) {
        border-bottom: 1px dashed #e0e0e0;
      }
      &-title {
        width: 100px;
        color: #686868;
        text-align: right;
        padding: 4px 15px 4px 0px;
      }
      &-content {
        flex: 1;
        display: flex;
        .notice-type-content {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          height: 32px;
          overflow: hidden;
          &-item {
            padding: 4px 7px;
            cursor: pointer;
            margin: 0 2px 10px 0;
            color: #398ce2;
          }
          .active {
            background-color: #398ce2;
            color: #ffffff;
            border-radius: 2px;
          }
        }

        // 更多操作
        .showmore-tools {
          width: 60px;
          padding: 4px 7px;
          cursor: pointer;
          color: #686868;
        }

        .price-range {
          display: flex;
          width: 500px;
          &-item {
            flex: 1;
            &-delive {
              width: 20px;
              line-height: 28px;
              text-align: center;
            }

            &-btn {
              width: 100px;
              margin-left: 5px;
            }
          }
        }

        .area-content {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          height: 30px;
          overflow: hidden;
          &-item {
            padding: 4px 7px;
            cursor: pointer;
            margin: 0 2px 10px 0;
            color: #398ce2;
          }
          .active {
            background-color: #398ce2;
            color: #ffffff;
            border-radius: 2px;
          }
        }
      }
    }
  }
}

.result-count {
  margin-top: 10px;
  background: #f1f1f1;
  padding: 10px;
}

// 分页
.project-page {
  padding: 10px;
  text-align: right;
}

/deep/ .el-table__empty-text {
  width: 100%;
}
</style>
