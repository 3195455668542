/*
 * @Date: 2021-11-10 14:14:01
 * @LastEditors: wxb
 * @LastEditTime: 2021-11-10 15:11:55
 * @Description:
 */

import request from '@/utils/request'

/**
 * 查询项目业绩信息
 * @param {*} params
 * @returns
 */
export function getPerformanceList(params) {
  return request({
    url: '/api/company/company/getPerformanceList',
    method: 'get',
    params
  })
}
